import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import { CartProvider } from './context/CartContext';

// Layouts
import AdminLayout from './components/layouts/AdminLayout';
import PublicLayout from './components/layouts/PublicLayout';

// Páginas públicas
import Login from './pages/public/Login';
import Register from './pages/public/Register';
import MenuPage from './pages/public/MenuPage';
import NotFound from './pages/public/NotFound';
import TestPage from './pages/public/TestPage';

// Páginas de administración
import Dashboard from './pages/admin/Dashboard';
import RestaurantList from './pages/admin/restaurants/RestaurantList';
import RestaurantForm from './pages/admin/restaurants/RestaurantForm';
import RestaurantDetail from './pages/admin/restaurants/RestaurantDetail';
import MenuManagement from './pages/admin/menu/MenuManagement';
import TableManagement from './pages/admin/tables/TableManagement';
import OrderManagement from './pages/admin/orders/OrderManagement';
import OrderDetail from './pages/admin/orders/OrderDetail';

// Ruta protegida que redirige a login si no hay autenticación
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  
  if (loading) {
    return <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-600"></div>
    </div>;
  }
  
  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }
  
  return children;
};

function App() {
  return (
    <CartProvider>
      <Routes>
        {/* Rutas públicas */}
        <Route path="/" element={<PublicLayout />}>
          <Route index element={<Navigate to="/login" />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="menu/:restaurantId/:tableNumber" element={<MenuPage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        
        {/* Rutas de administración (protegidas) */}
        <Route path="/admin" element={
          <ProtectedRoute>
            <AdminLayout />
          </ProtectedRoute>
        }>
          <Route index element={<Dashboard />} />
          <Route path="restaurants" element={<RestaurantList />} />
          <Route path="restaurants/new" element={<RestaurantForm />} />
          <Route path="restaurants/:id" element={<RestaurantDetail />} />
          <Route path="restaurants/:id/edit" element={<RestaurantForm />} />
          <Route path="restaurants/:id/menu" element={<MenuManagement />} />
          <Route path="restaurants/:id/tables" element={<TableManagement />} />
          <Route path="restaurants/:id/orders" element={<OrderManagement />} />
          <Route path="orders/:id" element={<OrderDetail />} />
        </Route>
      </Routes>
    </CartProvider>
  );
}

export default App;

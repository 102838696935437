import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { restaurantService } from '../../../services/api';

const RestaurantList = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchRestaurants();
  }, []);

  const fetchRestaurants = async () => {
    try {
      setLoading(true);
      const response = await restaurantService.getUserRestaurants();
      setRestaurants(response.data.restaurants);
    } catch (error) {
      console.error('Error al obtener restaurantes:', error);
      setError('No se pudieron cargar los restaurantes. Por favor, intenta nuevamente.');
      toast.error('Error al cargar restaurantes');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRestaurant = async (id) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este restaurante? Esta acción no se puede deshacer.')) {
      try {
        await restaurantService.deleteRestaurant(id);
        setRestaurants(restaurants.filter(restaurant => restaurant.id !== id));
        toast.success('Restaurante eliminado con éxito');
      } catch (error) {
        console.error('Error al eliminar restaurante:', error);
        toast.error('Error al eliminar restaurante');
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-md mb-6">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error</h3>
            <div className="mt-2 text-sm text-red-700">
              <p>{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="sm:flex sm:items-center sm:justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Mis Restaurantes</h1>
          <p className="mt-1 text-sm text-gray-500">
            Administra tus restaurantes, menús, mesas y pedidos
          </p>
        </div>
        <div className="mt-4 sm:mt-0">
          <Link
            to="/admin/restaurants/new"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Nuevo Restaurante
          </Link>
        </div>
      </div>

      {restaurants.length === 0 ? (
        <div className="bg-white shadow rounded-lg p-6 text-center">
          <h3 className="text-lg font-medium text-gray-900 mb-2">No tienes restaurantes</h3>
          <p className="text-gray-500 mb-4">Crea tu primer restaurante para comenzar a recibir pedidos</p>
          <Link
            to="/admin/restaurants/new"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Crear Restaurante
          </Link>
        </div>
      ) : (
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {restaurants.map((restaurant) => (
              <li key={restaurant.id}>
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      {restaurant.logo ? (
                        <img
                          src={restaurant.logo}
                          alt={restaurant.name}
                          className="h-12 w-12 rounded-full object-cover mr-4"
                        />
                      ) : (
                        <div className="h-12 w-12 rounded-full bg-primary-100 flex items-center justify-center mr-4">
                          <span className="text-primary-700 font-bold text-lg">
                            {restaurant.name.charAt(0)}
                          </span>
                        </div>
                      )}
                      <div>
                        <h3 className="text-lg font-medium text-gray-900">
                          <Link to={`/admin/restaurants/${restaurant.id}`} className="hover:underline">
                            {restaurant.name}
                          </Link>
                        </h3>
                        <p className="text-sm text-gray-500">{restaurant.address}</p>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <Link
                        to={`/admin/restaurants/${restaurant.id}/menu`}
                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                      >
                        Menú
                      </Link>
                      <Link
                        to={`/admin/restaurants/${restaurant.id}/tables`}
                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                      >
                        Mesas
                      </Link>
                      <Link
                        to={`/admin/restaurants/${restaurant.id}/orders`}
                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700"
                      >
                        Pedidos
                      </Link>
                      <Link
                        to={`/admin/restaurants/${restaurant.id}/edit`}
                        className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Editar
                      </Link>
                      <button
                        onClick={() => handleDeleteRestaurant(restaurant.id)}
                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default RestaurantList;

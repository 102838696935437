import axios from 'axios';

// Forzar la URL de la API a la versión de producción
// La URL base sin /api al final, ya que las rutas incluyen el prefijo /api
const API_URL = 'https://api.autopedidos.com';

// Log para depuración
console.log('API URL:', API_URL);

// Configuración global para todas las peticiones
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Content-Type'] = 'application/json';

// Configurar axios para incluir el token en todas las peticiones
export const setupAxiosInterceptors = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
  
  // Interceptor para manejar errores en las respuestas
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error('Error en la petición API:', error.message || error);
      return Promise.reject(error);
    }
  );
};

// Servicios de autenticación
export const authService = {
  register: (userData) => axios.post(`${API_URL}/api/auth/register`, userData),
  login: (credentials) => axios.post(`${API_URL}/api/auth/login`, credentials),
  getCurrentUser: () => axios.get(`${API_URL}/api/auth/me`)
};

// Servicios de restaurantes
export const restaurantService = {
  createRestaurant: (data) => axios.post(`${API_URL}/api/restaurants`, data),
  getUserRestaurants: () => axios.get(`${API_URL}/api/restaurants`),
  getRestaurant: (id) => axios.get(`${API_URL}/api/restaurants/${id}`),
  updateRestaurant: (id, data) => axios.put(`${API_URL}/api/restaurants/${id}`, data),
  deleteRestaurant: (id) => axios.delete(`${API_URL}/api/restaurants/${id}`),
  getPublicRestaurant: (id) => axios.get(`${API_URL}/api/restaurants/public/${id}`)
};

// Servicios de categorías
export const categoryService = {
  createCategory: (data) => axios.post(`${API_URL}/api/menu/categories`, data),
  getRestaurantCategories: (restaurantId) => 
    axios.get(`${API_URL}/api/menu/categories/restaurant/${restaurantId}`),
  updateCategory: (id, data) => axios.put(`${API_URL}/api/menu/categories/${id}`, data),
  deleteCategory: (id) => axios.delete(`${API_URL}/api/menu/categories/${id}`)
};

// Servicios de items del menú
export const menuItemService = {
  createMenuItem: (data) => axios.post(`${API_URL}/api/menu/items`, data),
  getRestaurantMenuItems: (restaurantId) => 
    axios.get(`${API_URL}/api/menu/items/restaurant/${restaurantId}`),
  getCategoryMenuItems: (categoryId) => 
    axios.get(`${API_URL}/api/menu/items/category/${categoryId}`),
  updateMenuItem: (id, data) => axios.put(`${API_URL}/api/menu/items/${id}`, data),
  deleteMenuItem: (id) => axios.delete(`${API_URL}/api/menu/items/${id}`),
  getPublicMenu: (restaurantId) => 
    axios.get(`${API_URL}/api/menu/public/restaurant/${restaurantId}`)
};

// Servicios de mesas
export const tableService = {
  createTable: (data) => axios.post(`${API_URL}/api/tables`, data),
  getRestaurantTables: (restaurantId) => 
    axios.get(`${API_URL}/api/tables/restaurant/${restaurantId}`),
  getTable: (id) => axios.get(`${API_URL}/api/tables/${id}`),
  updateTable: (id, data) => axios.put(`${API_URL}/api/tables/${id}`, data),
  deleteTable: (id) => axios.delete(`${API_URL}/api/tables/${id}`),
  verifyTable: (restaurantId, tableNumber) => 
    axios.get(`${API_URL}/api/tables/verify/${restaurantId}/${tableNumber}`)
};

// Servicios de pedidos
export const orderService = {
  createOrder: (data) => axios.post(`${API_URL}/api/orders`, data),
  createManualOrder: (data) => axios.post(`${API_URL}/api/orders/manual`, data),
  getRestaurantOrders: (params) => {
    // Si params es un string o número, asumimos que es el restaurant_id
    if (typeof params === 'string' || typeof params === 'number') {
      return axios.get(`${API_URL}/api/orders/restaurant/${params}`);
    }
    
    // Si es un objeto, extraemos los parámetros
    const { restaurant_id, status, start_date, end_date } = params;
    let url = `${API_URL}/api/orders/restaurant/${restaurant_id}`;
    
    // Construir query string con los parámetros de filtro
    const queryParams = [];
    if (status) queryParams.push(`status=${status}`);
    if (start_date) queryParams.push(`start_date=${start_date}`);
    if (end_date) queryParams.push(`end_date=${end_date}`);
    
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }
    
    return axios.get(url);
  },
  getOrder: (id) => axios.get(`${API_URL}/api/orders/${id}`),
  updateOrderStatus: (id, status) => axios.put(`${API_URL}/api/orders/${id}/status`, { status }),
  getTableActiveOrders: (restaurantId, tableId) => 
    axios.get(`${API_URL}/api/orders/table/${restaurantId}/${tableId}`)
};

export default {
  setupAxiosInterceptors,
  authService,
  restaurantService,
  categoryService,
  menuItemService,
  tableService,
  orderService
};

import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import './index.css'
import App from './App.jsx'
import { AuthProvider } from './context/AuthContext'
import axios from 'axios'

// Importar la solución para las rutas API
import fixApiRoutes from './api-fix.js'

// Aplicar la solución inmediatamente
fixApiRoutes()

// FORZAR LA URL DE LA API GLOBALMENTE
window.API_URL = 'https://api.autopedidos.com';
axios.defaults.baseURL = 'https://api.autopedidos.com';

// Sobrescribir los métodos HTTP de Axios para forzar el prefijo /api
const originalGet = axios.get;
axios.get = function(url, config) {
  url = ensureApiPrefix(url);
  console.log('GET:', url);
  return originalGet(url, config);
};

const originalPost = axios.post;
axios.post = function(url, data, config) {
  url = ensureApiPrefix(url);
  console.log('POST:', url);
  return originalPost(url, data, config);
};

const originalPut = axios.put;
axios.put = function(url, data, config) {
  url = ensureApiPrefix(url);
  console.log('PUT:', url);
  return originalPut(url, data, config);
};

const originalDelete = axios.delete;
axios.delete = function(url, config) {
  url = ensureApiPrefix(url);
  console.log('DELETE:', url);
  return originalDelete(url, config);
};

// Función para asegurar que todas las URLs incluyan el prefijo /api
function ensureApiPrefix(url) {
  // Si la URL ya es absoluta o ya tiene el prefijo /api, no hacer nada
  if (url.startsWith('http') || url.includes('/api/')) {
    return url;
  }
  
  // Si la URL comienza con /auth, /restaurants, /menu, /tables, /orders, etc.
  if (url.match(/^\/(?:auth|restaurants|menu|tables|orders|users)/))
  {
    return url.replace(/^\//, '/api/');
  }
  
  return url;
}

// Sobrescribir cualquier configuración existente de axios
axios.interceptors.request.use(config => {
  // Forzar la URL base en cada solicitud
  config.baseURL = 'https://api.autopedidos.com';
  
  // Evitar que se use localhost
  if (config.url && config.url.includes('localhost')) {
    config.url = config.url.replace(/http:\/\/localhost:[0-9]+/, 'https://api.autopedidos.com');
  }
  
  // FORZAR el prefijo /api en todas las rutas de autenticación y API
  if (config.url) {
    // Verificar si la URL ya tiene el prefijo /api
    if (!config.url.includes('/api/')) {
      // Si la URL comienza con /auth, /restaurants, /menu, /tables, /orders, etc.
      if (/^\/(?:auth|restaurants|menu|tables|orders|users)/.test(config.url)) {
        const originalUrl = config.url;
        config.url = config.url.replace(/^\//, '/api/');
        console.log(`🔄 Ruta corregida: ${originalUrl} → ${config.url}`);
      }
    }
  }
  
  console.log('Interceptor de solicitud:', config.url);
  return config;
});

// Imprimir información de depuración antes de renderizar
console.log('Iniciando renderizado de la aplicación');

const rootElement = document.getElementById('root');

if (!rootElement) {
  console.error('No se encontró el elemento root en el DOM');
} else {
  console.log('Elemento root encontrado');
  
  try {
    const root = createRoot(rootElement);
    
    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <AuthProvider>
            <App />
            <Toaster 
              position="top-center" 
              toastOptions={{
                // Prevenir duplicados
                duration: 2000,
                id: 'unique-notification',
                style: {
                  background: '#363636',
                  color: '#fff',
                }
              }}
            />
          </AuthProvider>
        </BrowserRouter>
      </React.StrictMode>
    );
    
    console.log('Renderizado completado');
  } catch (error) {
    console.error('Error al renderizar la aplicación:', error);
  }
}

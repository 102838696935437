import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { orderService, restaurantService } from '../../../services/api';

const OrderManagement = () => {
  const { id: restaurantId } = useParams();
  const [restaurant, setRestaurant] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderDetailModalOpen, setOrderDetailModalOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState('all');
  const [dateFilter, setDateFilter] = useState('today');
  const [customDateRange, setCustomDateRange] = useState({
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0]
  });

  useEffect(() => {
    fetchData();
    
    // Configurar actualización periódica de pedidos (cada 30 segundos)
    const intervalId = setInterval(() => {
      fetchOrders();
    }, 30000);
    
    return () => clearInterval(intervalId);
  }, [restaurantId, statusFilter, dateFilter, customDateRange]);

  const fetchData = async () => {
    try {
      setLoading(true);
      
      // Obtener datos del restaurante
      const restaurantResponse = await restaurantService.getRestaurant(restaurantId);
      setRestaurant(restaurantResponse.data.restaurant);
      
      // Obtener pedidos
      await fetchOrders();
      
    } catch (error) {
      console.error('Error al cargar datos de pedidos:', error);
      setError('No se pudieron cargar los datos de los pedidos');
      toast.error('Error al cargar los pedidos');
    } finally {
      setLoading(false);
    }
  };

  const fetchOrders = async () => {
    try {
      // Preparar parámetros de filtro
      const params = { restaurant_id: restaurantId };
      
      // Filtro por estado
      if (statusFilter !== 'all') {
        params.status = statusFilter;
      }
      
      // Filtro por fecha
      let startDate, endDate;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      switch (dateFilter) {
        case 'today':
          startDate = today;
          endDate = new Date();
          break;
        case 'yesterday':
          startDate = new Date(today);
          startDate.setDate(startDate.getDate() - 1);
          endDate = new Date(today);
          endDate.setMilliseconds(-1);
          break;
        case 'last7days':
          startDate = new Date(today);
          startDate.setDate(startDate.getDate() - 6);
          endDate = new Date();
          break;
        case 'last30days':
          startDate = new Date(today);
          startDate.setDate(startDate.getDate() - 29);
          endDate = new Date();
          break;
        case 'custom':
          startDate = new Date(customDateRange.startDate);
          endDate = new Date(customDateRange.endDate);
          endDate.setHours(23, 59, 59, 999);
          break;
        default:
          startDate = null;
          endDate = null;
      }
      
      if (startDate && endDate) {
        params.start_date = startDate.toISOString();
        params.end_date = endDate.toISOString();
      }
      
      const ordersResponse = await orderService.getRestaurantOrders(params);
      setOrders(ordersResponse.data.orders);
      
    } catch (error) {
      console.error('Error al obtener pedidos:', error);
      toast.error('Error al actualizar los pedidos');
    }
  };

  const openOrderDetailModal = (order) => {
    setSelectedOrder(order);
    setOrderDetailModalOpen(true);
  };

  const handleUpdateOrderStatus = async (orderId, newStatus) => {
    try {
      // Enviar directamente el estado como string
      await orderService.updateOrderStatus(orderId, newStatus);
      toast.success(`Estado del pedido actualizado a: ${getStatusText(newStatus)}`);
      
      // Actualizar el estado del pedido en la lista local
      setOrders(orders.map(order => 
        order.id === orderId ? { ...order, status: newStatus } : order
      ));
      
      // Si el modal está abierto, actualizar también el pedido seleccionado
      if (selectedOrder && selectedOrder.id === orderId) {
        setSelectedOrder({ ...selectedOrder, status: newStatus });
      }
    } catch (error) {
      console.error('Error al actualizar estado del pedido:', error);
      toast.error('Error al actualizar el estado del pedido');
    }
  };

  const formatDate = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  // Función para imprimir el pedido
  const handlePrintOrder = (order) => {
    // Crear contenido HTML para la impresión
    const printContent = `
      <html>
      <head>
        <title>Pedido #${order.order_number || (order.id && typeof order.id === 'string' ? order.id.substring(0, 8) : order.id)}</title>
        <style>
          body { font-family: Arial, sans-serif; margin: 0; padding: 20px; }
          h1 { font-size: 18px; margin-bottom: 10px; }
          h2 { font-size: 16px; margin-bottom: 10px; }
          .header { text-align: center; margin-bottom: 20px; }
          .info { margin-bottom: 15px; }
          .items { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
          .items th, .items td { border-bottom: 1px solid #ddd; padding: 8px; text-align: left; }
          .total { text-align: right; margin-top: 10px; }
          .footer { margin-top: 30px; font-size: 12px; text-align: center; }
          @media print {
            body { margin: 0; padding: 10px; }
          }
        </style>
      </head>
      <body>
        <div class="header">
          <h1>${restaurant?.name || 'Restaurante'}</h1>
          <p>${new Date().toLocaleString()}</p>
        </div>
        
        <div class="info">
          <h2>Pedido #${order.order_number || (order.id && typeof order.id === 'string' ? order.id.substring(0, 8) : order.id)}</h2>
          <p>Mesa: ${order.table_number || 'N/A'}</p>
          <p>Fecha: ${formatDate(order.created_at)}</p>
          <p>Estado: ${getStatusText(order.status)}</p>
        </div>
        
        <table class="items">
          <thead>
            <tr>
              <th>Cant.</th>
              <th>Descripción</th>
              <th>Precio</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            ${order.items.map(item => `
              <tr>
                <td>${item.quantity}</td>
                <td>${item.item_name || item.name || 'Ítem sin nombre'}</td>
                <td>$${formatCurrency(item.price).replace('$', '')}</td>
                <td>$${formatCurrency(item.price * item.quantity).replace('$', '')}</td>
              </tr>
              ${item.notes ? `<tr><td></td><td colspan="3"><small>Nota: ${item.notes}</small></td></tr>` : ''}
            `).join('')}
          </tbody>
        </table>
        
        <div class="total">
          <p><strong>Total: $${formatCurrency(order.total_amount).replace('$', '')}</strong></p>
        </div>
        
        <div class="footer">
          <p>¡Gracias por su pedido!</p>
        </div>
      </body>
      </html>
    `;
    
    // Crear ventana de impresión
    const printWindow = window.open('', '_blank');
    printWindow.document.write(printContent);
    printWindow.document.close();
    
    // Esperar a que se carguen los estilos y luego imprimir
    printWindow.onload = function() {
      printWindow.print();
      // Opcional: cerrar la ventana después de imprimir
      // printWindow.close();
    };
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'pending':
        return 'Pendiente';
      case 'preparing':
        return 'En preparación';
      case 'ready':
        return 'Listo para servir';
      case 'delivered':
        return 'Entregado';
      case 'cancelled':
        return 'Cancelado';
      default:
        return status;
    }
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'preparing':
        return 'bg-blue-100 text-blue-800';
      case 'ready':
        return 'bg-green-100 text-green-800';
      case 'delivered':
        return 'bg-gray-100 text-gray-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const handleDateFilterChange = (e) => {
    setDateFilter(e.target.value);
  };

  const handleCustomDateChange = (e) => {
    const { name, value } = e.target;
    setCustomDateRange({
      ...customDateRange,
      [name]: value
    });
  };

  if (loading && !orders.length) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-md mb-6">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error</h3>
            <div className="mt-2 text-sm text-red-700">
              <p>{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between mb-6">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Pedidos de {restaurant?.name}
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            Gestiona los pedidos de tu restaurante
          </p>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4 space-x-2">
          <Link
            to={`/admin/restaurants/${restaurantId}`}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Volver al restaurante
          </Link>
          <button
            type="button"
            onClick={fetchOrders}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Actualizar
          </button>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Filtros</h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="status-filter" className="block text-sm font-medium text-gray-700">
                Estado
              </label>
              <select
                id="status-filter"
                name="status-filter"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
              >
                <option value="all">Todos los estados</option>
                <option value="pending">Pendiente</option>
                <option value="preparing">En preparación</option>
                <option value="ready">Listo para servir</option>
                <option value="delivered">Entregado</option>
                <option value="cancelled">Cancelado</option>
              </select>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="date-filter" className="block text-sm font-medium text-gray-700">
                Fecha
              </label>
              <select
                id="date-filter"
                name="date-filter"
                value={dateFilter}
                onChange={handleDateFilterChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
              >
                <option value="today">Hoy</option>
                <option value="yesterday">Ayer</option>
                <option value="last7days">Últimos 7 días</option>
                <option value="last30days">Últimos 30 días</option>
                <option value="custom">Rango personalizado</option>
              </select>
            </div>

            {dateFilter === 'custom' && (
              <>
                <div className="sm:col-span-3">
                  <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">
                    Fecha inicial
                  </label>
                  <input
                    type="date"
                    name="startDate"
                    id="startDate"
                    value={customDateRange.startDate}
                    onChange={handleCustomDateChange}
                    className="mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="sm:col-span-3">
                  <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">
                    Fecha final
                  </label>
                  <input
                    type="date"
                    name="endDate"
                    id="endDate"
                    value={customDateRange.endDate}
                    onChange={handleCustomDateChange}
                    className="mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Pedidos</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {orders.length} {orders.length === 1 ? 'pedido encontrado' : 'pedidos encontrados'}
            </p>
          </div>
        </div>
        <div className="border-t border-gray-200">
          {orders.length === 0 ? (
            <div className="px-4 py-5 sm:p-6 text-center">
              <p className="text-gray-500">No hay pedidos que coincidan con los filtros seleccionados.</p>
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Pedido #
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Mesa
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Fecha
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Total
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Estado
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {orders.map((order) => (
                    <tr key={order.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        #{order.order_number || (order.id && typeof order.id === 'string' ? order.id.substring(0, 8) : order.id)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        Mesa {order.table_number || 'N/A'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatDate(order.created_at)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {formatCurrency(order.total_amount)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeClass(order.status)}`}>
                          {getStatusText(order.status)}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          type="button"
                          onClick={() => openOrderDetailModal(order)}
                          className="text-primary-600 hover:text-primary-900 mr-4"
                        >
                          Ver detalles
                        </button>
                        <button
                          type="button"
                          onClick={() => handlePrintOrder(order)}
                          className="text-indigo-600 hover:text-indigo-900 mr-4"
                        >
                          Imprimir
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {/* Modal de Detalles del Pedido */}
      {orderDetailModalOpen && selectedOrder && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 flex justify-between">
                      <span>Pedido #{selectedOrder.order_number || (selectedOrder.id && typeof selectedOrder.id === 'string' ? selectedOrder.id.substring(0, 8) : selectedOrder.id)}</span>
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeClass(selectedOrder.status)}`}>
                        {getStatusText(selectedOrder.status)}
                      </span>
                    </h3>
                    <div className="mt-4">
                      <div className="mb-4">
                        <p className="text-sm text-gray-500">Mesa: {selectedOrder.table_number || 'N/A'}</p>
                        <p className="text-sm text-gray-500">Fecha: {formatDate(selectedOrder.created_at)}</p>
                        {selectedOrder.customer_name && (
                          <p className="text-sm text-gray-500">Cliente: {selectedOrder.customer_name}</p>
                        )}
                        {selectedOrder.notes && (
                          <div className="mt-2">
                            <p className="text-sm font-medium text-gray-700">Notas:</p>
                            <p className="text-sm text-gray-500">{selectedOrder.notes}</p>
                          </div>
                        )}
                      </div>
                      
                      <div className="border-t border-gray-200 pt-4">
                        <h4 className="text-sm font-medium text-gray-700 mb-2">Items del pedido:</h4>
                        <ul className="divide-y divide-gray-200">
                          {selectedOrder.items.map((item, index) => (
                            <li key={index} className="py-3">
                              <div className="flex justify-between">
                                <div>
                                  <p className="text-sm font-medium text-gray-900">
                                    {item.quantity}x {item.item_name || item.name || 'Ítem sin nombre'}
                                  </p>
                                  {item.notes && (
                                    <p className="text-xs text-gray-500 mt-1">{item.notes}</p>
                                  )}
                                </div>
                                <p className="text-sm text-gray-900">{formatCurrency(item.price * item.quantity)}</p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      
                      <div className="border-t border-gray-200 pt-4 mt-4">
                        <div className="flex justify-between text-sm">
                          <p className="font-medium text-gray-700">Subtotal:</p>
                          <p className="text-gray-900">{formatCurrency(selectedOrder.subtotal || selectedOrder.total_amount)}</p>
                        </div>
                        {selectedOrder.tax && (
                          <div className="flex justify-between text-sm mt-1">
                            <p className="font-medium text-gray-700">Impuestos:</p>
                            <p className="text-gray-900">{formatCurrency(selectedOrder.tax)}</p>
                          </div>
                        )}
                        <div className="flex justify-between text-base font-medium mt-2">
                          <p className="text-gray-900">Total:</p>
                          <p className="text-gray-900">{formatCurrency(selectedOrder.total_amount)}</p>
                        </div>
                      </div>
                      
                      {selectedOrder.status !== 'delivered' && selectedOrder.status !== 'cancelled' && (
                        <div className="border-t border-gray-200 pt-4 mt-4">
                          <h4 className="text-sm font-medium text-gray-700 mb-2">Actualizar estado:</h4>
                          <div className="grid grid-cols-2 gap-2">
                            {selectedOrder.status === 'pending' && (
                              <>
                                <button
                                  type="button"
                                  onClick={() => handleUpdateOrderStatus(selectedOrder.id, 'preparing')}
                                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                  En preparación
                                </button>
                                <button
                                  type="button"
                                  onClick={() => handleUpdateOrderStatus(selectedOrder.id, 'cancelled')}
                                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                >
                                  Cancelar
                                </button>
                              </>
                            )}
                            {selectedOrder.status === 'preparing' && (
                              <button
                                type="button"
                                onClick={() => handleUpdateOrderStatus(selectedOrder.id, 'ready')}
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                              >
                                Listo para servir
                              </button>
                            )}
                            {selectedOrder.status === 'ready' && (
                              <button
                                type="button"
                                onClick={() => handleUpdateOrderStatus(selectedOrder.id, 'delivered')}
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                              >
                                Entregado
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={() => setOrderDetailModalOpen(false)}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderManagement;

import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { tableService, restaurantService } from '../../../services/api';
import { QRCodeSVG } from 'qrcode.react';

const TableManagement = () => {
  const { id: restaurantId } = useParams();
  const [restaurant, setRestaurant] = useState(null);
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Estados para modal
  const [tableModalOpen, setTableModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  
  // Estado para formulario
  const [tableForm, setTableForm] = useState({
    table_number: '',
    capacity: '0',
    status: 'available'
  });

  // URL base para QR
  const baseUrl = window.location.origin;

  useEffect(() => {
    fetchData();
  }, [restaurantId]);

  const fetchData = async () => {
    try {
      setLoading(true);
      
      // Obtener datos del restaurante
      const restaurantResponse = await restaurantService.getRestaurant(restaurantId);
      setRestaurant(restaurantResponse.data.restaurant);
      
      // Obtener mesas
      const tablesResponse = await tableService.getRestaurantTables(restaurantId);
      setTables(tablesResponse.data.tables);
      
    } catch (error) {
      console.error('Error al cargar datos de mesas:', error);
      setError('No se pudieron cargar los datos de las mesas');
      toast.error('Error al cargar las mesas');
    } finally {
      setLoading(false);
    }
  };

  const openTableModal = (table = null) => {
    if (table) {
      setTableForm({
        table_number: table.table_number.toString(),
        capacity: (table.capacity || '0').toString(),
        status: table.status || 'available'
      });
      setSelectedTable(table);
      setEditMode(true);
    } else {
      // Encontrar el número de mesa más alto y sugerir el siguiente
      const nextTableNumber = tables.length > 0 
        ? Math.max(...tables.map(t => parseInt(t.table_number))) + 1 
        : 1;
      
      setTableForm({
        table_number: nextTableNumber.toString(),
        capacity: '4',
        status: 'available'
      });
      setSelectedTable(null);
      setEditMode(false);
    }
    setTableModalOpen(true);
  };

  const openQrModal = (table) => {
    // Asegurarnos de que estamos usando el campo table_number
    console.log('Mesa seleccionada:', table);
    setSelectedTable(table);
    setQrModalOpen(true);
  };

  const handleTableChange = (e) => {
    const { name, value } = e.target;
    setTableForm({
      ...tableForm,
      [name]: value
    });
  };

  const validateForm = () => {
    if (!tableForm.table_number.trim() || isNaN(parseInt(tableForm.table_number)) || parseInt(tableForm.table_number) <= 0) {
      toast.error('El número de mesa debe ser un número positivo');
      return false;
    }
    
    if (tableForm.capacity.trim() === '' || isNaN(parseInt(tableForm.capacity)) || parseInt(tableForm.capacity) < 0) {
      toast.error('La capacidad debe ser un número válido');
      return false;
    }
    
    // Verificar que el número de mesa no esté duplicado (excepto en modo edición para la misma mesa)
    const tableNumber = parseInt(tableForm.table_number);
    const duplicateTable = tables.find(t => 
      parseInt(t.table_number) === tableNumber && 
      (!editMode || (editMode && t.id !== selectedTable.id))
    );
    
    if (duplicateTable) {
      toast.error('Ya existe una mesa con este número');
      return false;
    }
    
    return true;
  };

  const handleTableSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    try {
      // Importante: table_number debe ser un string según la definición de la base de datos
      const formData = {
        table_number: tableForm.table_number,
        capacity: parseInt(tableForm.capacity),
        status: tableForm.status,
        restaurant_id: restaurantId
      };
      
      if (editMode && selectedTable) {
        await tableService.updateTable(selectedTable.id, formData);
        toast.success('Mesa actualizada con éxito');
      } else {
        await tableService.createTable(formData);
        toast.success('Mesa creada con éxito');
      }
      
      setTableModalOpen(false);
      fetchData();
    } catch (error) {
      console.error('Error al guardar mesa:', error);
      toast.error('Error al guardar la mesa');
    }
  };

  const handleDeleteTable = async (tableId) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar esta mesa?')) {
      try {
        const response = await tableService.deleteTable(tableId);
        if (response.data && response.data.success) {
          toast.success('Mesa eliminada con éxito');
          fetchData();
        } else {
          const errorMsg = response.data?.message || 'No se pudo eliminar la mesa';
          toast.error(errorMsg);
        }
      } catch (error) {
        console.error('Error al eliminar mesa:', error);
        const errorMsg = error.response?.data?.message || 'Error al eliminar la mesa';
        toast.error(errorMsg);
      }
    }
  };

  const handlePrintQR = () => {
    const printWindow = window.open('', '_blank');
    const qrUrl = `${baseUrl}/menu/${restaurantId}/${selectedTable.table_number || selectedTable.number}`;
    
    printWindow.document.write(`
      <html>
        <head>
          <title>QR Code - Mesa ${selectedTable.table_number}</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              text-align: center;
              padding: 20px;
            }
            .qr-container {
              margin: 0 auto;
              max-width: 300px;
            }
            .restaurant-name {
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 10px;
            }
            .table-number {
              font-size: 20px;
              margin-bottom: 20px;
            }
            .qr-code {
              margin-bottom: 20px;
            }
            .instructions {
              font-size: 14px;
              color: #666;
              margin-top: 20px;
            }
            @media print {
              .no-print {
                display: none;
              }
            }
          </style>
        </head>
        <body>
          <div class="qr-container">
            <div class="restaurant-name">${restaurant.name}</div>
            <div class="table-number">Mesa ${selectedTable.table_number}</div>
            <div class="qr-code">
              <img src="${QRCode.toDataURL(qrUrl, { width: 256 })}" width="256" height="256" />
            </div>
            <div>Escanea para ver el menú</div>
            <div class="instructions">
              Escanea el código QR con la cámara de tu teléfono para acceder al menú digital y realizar pedidos.
            </div>
            <button class="no-print" onclick="window.print()" style="margin-top: 20px; padding: 10px 20px;">
              Imprimir QR
            </button>
          </div>
        </body>
      </html>
    `);
    
    printWindow.document.close();
  };

  const handleDownloadQR = () => {
    const qrUrl = `${baseUrl}/menu/${restaurantId}/${selectedTable.table_number || selectedTable.number}`;
    const canvas = document.getElementById('qr-code-canvas');
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `qr-mesa-${selectedTable.number}-${restaurant.name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'available':
        return 'bg-green-100 text-green-800';
      case 'occupied':
        return 'bg-red-100 text-red-800';
      case 'reserved':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'available':
        return 'Disponible';
      case 'occupied':
        return 'Ocupada';
      case 'reserved':
        return 'Reservada';
      default:
        return status;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-md mb-6">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error</h3>
            <div className="mt-2 text-sm text-red-700">
              <p>{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between mb-6">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Mesas de {restaurant?.name}
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            Administra las mesas y genera códigos QR para cada una
          </p>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4 space-x-2">
          <Link
            to={`/admin/restaurants/${restaurantId}`}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Volver al restaurante
          </Link>
          <button
            type="button"
            onClick={() => openTableModal()}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Añadir Mesa
          </button>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Mesas</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Lista de mesas disponibles en el restaurante
          </p>
        </div>
        <div className="border-t border-gray-200">
          {tables.length === 0 ? (
            <div className="px-4 py-5 sm:p-6 text-center">
              <p className="text-gray-500">No hay mesas. Añade una para comenzar.</p>
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Mesa #
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Capacidad
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Estado
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {tables.map((table) => (
                    <tr key={table.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        Mesa #{table.table_number || table.number}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {table.capacity ? `${table.capacity} personas` : '0 personas'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeClass(table.status)}`}>
                          {getStatusText(table.status)}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          type="button"
                          onClick={() => openQrModal(table)}
                          className="text-primary-600 hover:text-primary-900 mr-4"
                        >
                          Ver QR
                        </button>
                        <button
                          type="button"
                          onClick={() => openTableModal(table)}
                          className="text-indigo-600 hover:text-indigo-900 mr-4"
                        >
                          Editar
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDeleteTable(table.id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {/* Modal de Mesa */}
      {tableModalOpen && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form onSubmit={handleTableSubmit}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {editMode ? 'Editar Mesa' : 'Nueva Mesa'}
                      </h3>
                      <div className="mt-4 space-y-4">
                        <div>
                          <label htmlFor="number" className="block text-sm font-medium text-gray-700">
                            Número de Mesa *
                          </label>
                          <input
                            type="number"
                            name="number"
                            id="number"
                            min="1"
                            value={tableForm.number}
                            onChange={handleTableChange}
                            className="mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="capacity" className="block text-sm font-medium text-gray-700">
                            Capacidad (personas) *
                          </label>
                          <input
                            type="number"
                            name="capacity"
                            id="capacity"
                            min="1"
                            value={tableForm.capacity}
                            onChange={handleTableChange}
                            className="mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                            Estado
                          </label>
                          <select
                            id="status"
                            name="status"
                            value={tableForm.status}
                            onChange={handleTableChange}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                          >
                            <option value="available">Disponible</option>
                            <option value="occupied">Ocupada</option>
                            <option value="reserved">Reservada</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {editMode ? 'Actualizar' : 'Crear'}
                  </button>
                  <button
                    type="button"
                    onClick={() => setTableModalOpen(false)}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Modal de QR */}
      {qrModalOpen && selectedTable && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Código QR - Mesa {selectedTable.table_number}
                    </h3>
                    <div className="mt-4 flex flex-col items-center">
                      <p className="mb-4 text-sm text-gray-500">
                        Este código QR dirige a los clientes al menú digital de esta mesa.
                      </p>
                      <div className="mb-4">
                        <QRCodeSVG
                          id="qr-code-canvas"
                          value={`${baseUrl}/menu/${restaurantId}/${selectedTable.table_number}`}
                          size={200}
                          level="H"
                          includeMargin={true}
                        />
                      </div>
                      <p className="text-xs text-gray-500 mb-4">
                        URL: {baseUrl}/menu/{restaurantId}/{selectedTable.table_number}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handlePrintQR}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Imprimir
                </button>
                <button
                  type="button"
                  onClick={handleDownloadQR}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-primary-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-primary-700 hover:bg-primary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Descargar
                </button>
                <button
                  type="button"
                  onClick={() => setQrModalOpen(false)}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableManagement;

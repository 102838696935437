import { Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

const PublicLayout = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Toaster position="top-right" />
      <main className="container mx-auto px-4 py-8">
        <Outlet />
      </main>
    </div>
  );
};

export default PublicLayout;

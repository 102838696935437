import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { categoryService, menuItemService, restaurantService } from '../../../services/api';

const MenuManagement = () => {
  const { id: restaurantId } = useParams();
  const [restaurant, setRestaurant] = useState(null);
  const [categories, setCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Estados para modales
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [menuItemModalOpen, setMenuItemModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  
  // Estados para formularios
  const [categoryForm, setCategoryForm] = useState({
    name: '',
    description: ''
  });
  
  const [menuItemForm, setMenuItemForm] = useState({
    name: '',
    description: '',
    price: '',
    image_url: '',
    category_id: ''
  });

  useEffect(() => {
    fetchData();
  }, [restaurantId]);

  const fetchData = async () => {
    try {
      setLoading(true);
      
      // Obtener datos del restaurante
      const restaurantResponse = await restaurantService.getRestaurant(restaurantId);
      setRestaurant(restaurantResponse.data.restaurant);
      
      // Obtener categorías
      const categoriesResponse = await categoryService.getRestaurantCategories(restaurantId);
      setCategories(categoriesResponse.data.categories);
      
      // Obtener items del menú
      const menuItemsResponse = await menuItemService.getRestaurantMenuItems(restaurantId);
      setMenuItems(menuItemsResponse.data.menuItems);
      
    } catch (error) {
      console.error('Error al cargar datos del menú:', error);
      setError('No se pudieron cargar los datos del menú');
      toast.error('Error al cargar el menú');
    } finally {
      setLoading(false);
    }
  };

  // Funciones para categorías
  const openCategoryModal = (category = null) => {
    if (category) {
      setCategoryForm({
        name: category.name,
        description: category.description || ''
      });
      setSelectedCategory(category);
      setEditMode(true);
    } else {
      setCategoryForm({
        name: '',
        description: ''
      });
      setSelectedCategory(null);
      setEditMode(false);
    }
    setCategoryModalOpen(true);
  };

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setCategoryForm({
      ...categoryForm,
      [name]: value
    });
  };

  const handleCategorySubmit = async (e) => {
    e.preventDefault();
    
    if (!categoryForm.name.trim()) {
      toast.error('El nombre de la categoría es requerido');
      return;
    }
    
    try {
      if (editMode && selectedCategory) {
        await categoryService.updateCategory(selectedCategory.id, {
          ...categoryForm,
          restaurant_id: restaurantId
        });
        toast.success('Categoría actualizada con éxito');
      } else {
        await categoryService.createCategory({
          ...categoryForm,
          restaurant_id: restaurantId
        });
        toast.success('Categoría creada con éxito');
      }
      
      setCategoryModalOpen(false);
      fetchData();
    } catch (error) {
      console.error('Error al guardar categoría:', error);
      toast.error('Error al guardar la categoría');
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar esta categoría? Se eliminarán también todos los items asociados.')) {
      try {
        await categoryService.deleteCategory(categoryId);
        toast.success('Categoría eliminada con éxito');
        fetchData();
      } catch (error) {
        console.error('Error al eliminar categoría:', error);
        toast.error('Error al eliminar la categoría');
      }
    }
  };

  // Funciones para items del menú
  const openMenuItemModal = (item = null) => {
    if (item) {
      setMenuItemForm({
        name: item.name,
        description: item.description || '',
        price: item.price.toString(),
        image_url: item.image_url || '',
        category_id: item.category_id
      });
      setSelectedMenuItem(item);
      setEditMode(true);
    } else {
      setMenuItemForm({
        name: '',
        description: '',
        price: '',
        image_url: '',
        category_id: categories.length > 0 ? categories[0].id : ''
      });
      setSelectedMenuItem(null);
      setEditMode(false);
    }
    setMenuItemModalOpen(true);
  };

  const handleMenuItemChange = (e) => {
    const { name, value } = e.target;
    setMenuItemForm({
      ...menuItemForm,
      [name]: value
    });
  };

  const handleMenuItemSubmit = async (e) => {
    e.preventDefault();
    
    if (!menuItemForm.name.trim()) {
      toast.error('El nombre del item es requerido');
      return;
    }
    
    if (!menuItemForm.price || isNaN(parseFloat(menuItemForm.price)) || parseFloat(menuItemForm.price) <= 0) {
      toast.error('El precio debe ser un número mayor que cero');
      return;
    }
    
    if (!menuItemForm.category_id) {
      toast.error('Debes seleccionar una categoría');
      return;
    }
    
    try {
      const formData = {
        ...menuItemForm,
        price: parseFloat(menuItemForm.price),
        restaurant_id: restaurantId
      };
      
      if (editMode && selectedMenuItem) {
        await menuItemService.updateMenuItem(selectedMenuItem.id, formData);
        toast.success('Item actualizado con éxito');
      } else {
        await menuItemService.createMenuItem(formData);
        toast.success('Item creado con éxito');
      }
      
      setMenuItemModalOpen(false);
      fetchData();
    } catch (error) {
      console.error('Error al guardar item del menú:', error);
      toast.error('Error al guardar el item');
    }
  };

  const handleDeleteMenuItem = async (itemId) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este item del menú?')) {
      try {
        await menuItemService.deleteMenuItem(itemId);
        toast.success('Item eliminado con éxito');
        fetchData();
      } catch (error) {
        console.error('Error al eliminar item:', error);
        toast.error('Error al eliminar el item');
      }
    }
  };

  // Función para obtener los items de una categoría
  const getCategoryItems = (categoryId) => {
    return menuItems.filter(item => item.category_id === categoryId);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-md mb-6">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error</h3>
            <div className="mt-2 text-sm text-red-700">
              <p>{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between mb-6">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Menú de {restaurant?.name}
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            Administra las categorías y platos del menú
          </p>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4 space-x-2">
          <Link
            to={`/admin/restaurants/${restaurantId}`}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Volver al restaurante
          </Link>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Categorías</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Organiza tu menú en categorías
            </p>
          </div>
          <button
            type="button"
            onClick={() => openCategoryModal()}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Añadir Categoría
          </button>
        </div>
        <div className="border-t border-gray-200">
          {categories.length === 0 ? (
            <div className="px-4 py-5 sm:p-6 text-center">
              <p className="text-gray-500">No hay categorías. Añade una para comenzar a crear tu menú.</p>
            </div>
          ) : (
            <ul className="divide-y divide-gray-200">
              {categories.map((category) => (
                <li key={category.id} className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <h4 className="text-lg font-medium text-gray-900">{category.name}</h4>
                      {category.description && (
                        <p className="mt-1 text-sm text-gray-500">{category.description}</p>
                      )}
                      <p className="mt-1 text-xs text-gray-400">
                        {getCategoryItems(category.id).length} items
                      </p>
                    </div>
                    <div className="flex space-x-2">
                      <button
                        type="button"
                        onClick={() => openCategoryModal(category)}
                        className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Editar
                      </button>
                      <button
                        type="button"
                        onClick={() => handleDeleteCategory(category.id)}
                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Items del Menú</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Platos y bebidas disponibles
            </p>
          </div>
          <button
            type="button"
            onClick={() => openMenuItemModal()}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            disabled={categories.length === 0}
          >
            Añadir Item
          </button>
        </div>
        <div className="border-t border-gray-200">
          {categories.length === 0 ? (
            <div className="px-4 py-5 sm:p-6 text-center">
              <p className="text-gray-500">Primero debes crear al menos una categoría.</p>
            </div>
          ) : menuItems.length === 0 ? (
            <div className="px-4 py-5 sm:p-6 text-center">
              <p className="text-gray-500">No hay items en el menú. Añade uno para comenzar.</p>
            </div>
          ) : (
            <div>
              {categories.map((category) => {
                const items = getCategoryItems(category.id);
                if (items.length === 0) return null;
                
                return (
                  <div key={category.id} className="px-4 py-5 sm:p-6 border-b border-gray-200 last:border-b-0">
                    <h4 className="text-lg font-medium text-gray-900 mb-4">{category.name}</h4>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                      {items.map((item) => (
                        <div key={item.id} className="border border-gray-200 rounded-lg overflow-hidden">
                          {item.image && (
                            <img
                              src={item.image}
                              alt={item.name}
                              className="w-full h-48 object-cover"
                            />
                          )}
                          <div className="p-4">
                            <h5 className="text-lg font-medium text-gray-900">{item.name}</h5>
                            <p className="mt-1 text-sm text-gray-500 line-clamp-2">{item.description}</p>
                            <p className="mt-2 text-lg font-bold text-primary-600">${item.price.toFixed(2)}</p>
                            <div className="mt-4 flex justify-end space-x-2">
                              <button
                                type="button"
                                onClick={() => openMenuItemModal(item)}
                                className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                              >
                                Editar
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDeleteMenuItem(item.id)}
                                className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
                              >
                                Eliminar
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {/* Modal de Categoría */}
      {categoryModalOpen && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form onSubmit={handleCategorySubmit}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {editMode ? 'Editar Categoría' : 'Nueva Categoría'}
                      </h3>
                      <div className="mt-4 space-y-4">
                        <div>
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Nombre *
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={categoryForm.name}
                            onChange={handleCategoryChange}
                            className="mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                            Descripción
                          </label>
                          <textarea
                            id="description"
                            name="description"
                            rows={3}
                            value={categoryForm.description}
                            onChange={handleCategoryChange}
                            className="mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {editMode ? 'Actualizar' : 'Crear'}
                  </button>
                  <button
                    type="button"
                    onClick={() => setCategoryModalOpen(false)}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Modal de Item del Menú */}
      {menuItemModalOpen && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form onSubmit={handleMenuItemSubmit}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {editMode ? 'Editar Item' : 'Nuevo Item'}
                      </h3>
                      <div className="mt-4 space-y-4">
                        <div>
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Nombre *
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={menuItemForm.name}
                            onChange={handleMenuItemChange}
                            className="mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                            Descripción
                          </label>
                          <textarea
                            id="description"
                            name="description"
                            rows={3}
                            value={menuItemForm.description}
                            onChange={handleMenuItemChange}
                            className="mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                            Precio *
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <span className="text-gray-500 sm:text-sm">$</span>
                            </div>
                            <input
                              type="number"
                              name="price"
                              id="price"
                              step="0.01"
                              min="0"
                              value={menuItemForm.price}
                              onChange={handleMenuItemChange}
                              className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                              placeholder="0.00"
                              required
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="image_url" className="block text-sm font-medium text-gray-700">
                            URL de la imagen
                          </label>
                          <input
                            type="text"
                            name="image_url"
                            id="image_url"
                            value={menuItemForm.image_url}
                            onChange={handleMenuItemChange}
                            className="mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <p className="mt-1 text-xs text-gray-500">
                            URL de la imagen del plato (opcional)
                          </p>
                        </div>
                        <div>
                          <label htmlFor="category_id" className="block text-sm font-medium text-gray-700">
                            Categoría *
                          </label>
                          <select
                            id="category_id"
                            name="category_id"
                            value={menuItemForm.category_id}
                            onChange={handleMenuItemChange}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                            required
                          >
                            <option value="">Selecciona una categoría</option>
                            {categories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {editMode ? 'Actualizar' : 'Crear'}
                  </button>
                  <button
                    type="button"
                    onClick={() => setMenuItemModalOpen(false)}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuManagement;

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { menuItemService, restaurantService, tableService, orderService } from '../../services/api';
import { useCart } from '../../context/CartContext';

const MenuPage = () => {
  const { restaurantId, tableNumber } = useParams();
  const [restaurant, setRestaurant] = useState(null);
  const [table, setTable] = useState(null);
  const [menuCategories, setMenuCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [notes, setNotes] = useState('');
  const [cartModalOpen, setCartModalOpen] = useState(false);
  
  const { 
    cartItems, 
    addToCart, 
    removeFromCart, 
    updateCartItemQuantity, 
    getCartTotal, 
    getCartItemsCount,
    setTableInfo,
    clearCart
  } = useCart();

  // Cargar datos del restaurante, mesa y menú
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Configurar axios para no enviar credenciales en rutas públicas
        const axiosPublic = axios.create({
          withCredentials: false,
          headers: { 'Content-Type': 'application/json' }
        });
        
        // URL base de la API - forzada a producción
        const API_URL = 'https://api.autopedidos.com';
        console.log('MenuPage API URL:', API_URL);
        
        // Verificar que la mesa existe
        const tableResponse = await axiosPublic.get(`${API_URL}/api/tables/verify/${restaurantId}/${tableNumber}`);
        if (tableResponse.data && tableResponse.data.table) {
          setTable(tableResponse.data.table);
        } else {
          throw new Error('Mesa no encontrada');
        }
        
        // Obtener datos del restaurante
        const restaurantResponse = await axiosPublic.get(`${API_URL}/api/restaurants/public/${restaurantId}`);
        if (restaurantResponse.data && restaurantResponse.data.restaurant) {
          setRestaurant(restaurantResponse.data.restaurant);
        } else {
          throw new Error('Restaurante no encontrado');
        }
        
        // Obtener menú del restaurante
        const menuResponse = await axiosPublic.get(`${API_URL}/api/menu/public/restaurant/${restaurantId}`);
        
        // La respuesta del servidor contiene 'menu' en lugar de 'categories'
        const menuCategories = menuResponse.data && menuResponse.data.menu ? menuResponse.data.menu : [];
        setMenuCategories(menuCategories);
        
        if (menuCategories.length > 0) {
          setSelectedCategory(menuCategories[0].id);
        }
        
        // Guardar información de la mesa en el contexto del carrito
        if (restaurant && table) {
          setTableInfo(restaurantResponse.data.restaurant, tableResponse.data.table);
        }
        
      } catch (error) {
        console.error('Error al cargar datos:', error);
        setError('No se pudo cargar el menú. Por favor, intenta nuevamente.');
        toast.error('Error al cargar el menú');
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, [restaurantId, tableNumber, setTableInfo]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setQuantity(1);
    setNotes('');
    setItemModalOpen(true);
  };

  const handleAddToCart = () => {
    // Implementación simple y directa
    console.log('Haciendo clic en Agregar al carrito');
    
    // Deshabilitar el botón para evitar múltiples clics
    const addButton = document.querySelector('button[type="button"][class*="bg-primary-600"]');
    if (addButton) {
      addButton.disabled = true;
      addButton.classList.add('opacity-50');
    }
    
    try {
      // Llamar a la función de agregar al carrito
      addToCart(selectedItem, quantity, notes);
    } finally {
      // Cerrar el modal
      setItemModalOpen(false);
      
      // Reactivar el botón después de un tiempo
      setTimeout(() => {
        if (addButton) {
          addButton.disabled = false;
          addButton.classList.remove('opacity-50');
        }
      }, 500);
    }
  };

  const handleViewCart = () => {
    setCartModalOpen(true);
  };

  // Generar un número de pedido único con formato de 1 letra y 3 números (ej: A001)
  const generateOrderNumber = () => {
    // Generar una letra mayúscula aleatoria (A-Z)
    const randomLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
    
    // Generar un número de 3 dígitos (001-999)
    const randomNumber = String(Math.floor(Math.random() * 999) + 1).padStart(3, '0');
    
    return `${randomLetter}${randomNumber}`;
  };
  
  const handlePlaceOrder = async () => {
    try {
      // Verificar que hay items en el carrito
      if (cartItems.length === 0) {
        toast.error('No hay items en el carrito', { id: 'empty-cart-error' });
        return;
      }

      // Deshabilitar múltiples envíos
      const orderInProgress = document.getElementById('order-in-progress');
      if (orderInProgress && orderInProgress.value === 'true') {
        return;
      }
      
      // Crear un elemento oculto para rastrear el estado del pedido
      if (!document.getElementById('order-in-progress')) {
        const trackingElement = document.createElement('input');
        trackingElement.type = 'hidden';
        trackingElement.id = 'order-in-progress';
        trackingElement.value = 'true';
        document.body.appendChild(trackingElement);
      } else {
        document.getElementById('order-in-progress').value = 'true';
      }

      // Generar un número de pedido único
      const orderNumber = generateOrderNumber();

      // Preparar los datos del pedido
      const orderData = {
        restaurant_id: restaurantId,
        table_id: table.id,
        table_number: table.table_number,
        order_number: orderNumber, // Incluir el número de pedido en los datos
        items: cartItems.map(item => ({
          menu_item_id: item.id,
          quantity: item.quantity,
          notes: item.notes || ''
        }))
        // No es necesario enviar el total, el servidor lo calculará
      };

      // Configurar axios para no enviar credenciales en rutas públicas
      const axiosPublic = axios.create({
        withCredentials: false,
        headers: { 'Content-Type': 'application/json' }
      });

      // URL base de la API - forzada a producción
      const API_URL = 'https://api.autopedidos.com';
      
      // Enviar el pedido al servidor directamente
      const response = await axiosPublic.post(`${API_URL}/api/orders`, orderData);
      
      if (response.data && response.data.success) {
        // Mostrar alerta con el número de pedido
        toast.success(
          <div>
            <p><strong>¡Pedido confirmado!</strong></p>
            <p>Número de pedido: <strong>{orderNumber}</strong></p>
          </div>, 
          { 
            id: 'order-success',
            duration: 5000 // Mostrar por más tiempo para que el cliente pueda ver el número
          }
        );
        
        // Crear una alerta más visible con el número de pedido
        const showOrderConfirmation = () => {
          // Crear un div para la alerta
          const alertDiv = document.createElement('div');
          alertDiv.className = 'fixed inset-0 flex items-center justify-center z-50';
          alertDiv.innerHTML = `
            <div class="fixed inset-0 bg-black bg-opacity-50"></div>
            <div class="bg-white rounded-lg p-8 max-w-md w-full mx-4 relative z-10 shadow-xl">
              <div class="text-center">
                <div class="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <svg class="w-10 h-10 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </div>
                <h2 class="text-2xl font-bold text-gray-900 mb-2">¡Pedido Confirmado!</h2>
                <p class="text-gray-600 mb-6">Tu pedido ha sido enviado con éxito.</p>
                <div class="bg-gray-100 p-4 rounded-lg mb-6">
                  <p class="text-gray-700 mb-1">Número de pedido:</p>
                  <p class="text-2xl font-bold text-primary-600">${orderNumber}</p>
                </div>
                <button id="close-alert" class="bg-primary-600 text-white px-6 py-2 rounded-lg font-medium hover:bg-primary-700 transition-colors">
                  Cerrar
                </button>
              </div>
            </div>
          `;
          
          document.body.appendChild(alertDiv);
          
          // Agregar evento para cerrar la alerta
          document.getElementById('close-alert').addEventListener('click', () => {
            document.body.removeChild(alertDiv);
          });
        };
        
        // Mostrar la alerta de confirmación
        showOrderConfirmation();
        
        // Limpiar el carrito después de enviar el pedido exitosamente
        clearCart();
        setCartModalOpen(false);
      } else {
        toast.error((response.data && response.data.message) || 'Error al enviar el pedido', { id: 'order-error' });
      }
    } catch (error) {
      console.error('Error al enviar el pedido:', error);
      toast.error('Error al enviar el pedido. Por favor, intenta nuevamente.', { id: 'order-error' });
    } finally {
      // Restablecer el estado del pedido
      if (document.getElementById('order-in-progress')) {
        document.getElementById('order-in-progress').value = 'false';
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-10">
        <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
        <p className="text-gray-700">{error}</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Encabezado del restaurante */}
      <div className="mb-8 text-center">
        {restaurant?.logo && (
          <img 
            src={restaurant.logo} 
            alt={restaurant.name} 
            className="h-24 w-auto mx-auto mb-4 rounded-lg"
          />
        )}
        <h1 className="text-3xl font-bold text-gray-900">{restaurant?.name}</h1>
        <p className="text-gray-600 mt-2">Mesa #{tableNumber}</p>
      </div>

      {/* Categorías del menú */}
      <div className="mb-6 overflow-x-auto">
        <div className="flex space-x-2 pb-2">
          {menuCategories.map((category) => (
            <button
              key={category.id}
              onClick={() => setSelectedCategory(category.id)}
              className={`px-4 py-2 rounded-full text-sm font-medium whitespace-nowrap ${
                selectedCategory === category.id
                  ? 'bg-primary-600 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              {category.name}
            </button>
          ))}
        </div>
      </div>

      {/* Items del menú */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
        {menuCategories
          .find((cat) => cat.id === selectedCategory)
          ?.items.map((item) => (
            <div
              key={item.id}
              className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-lg transition-shadow"
              onClick={() => handleItemClick(item)}
            >
              {item.image && (
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-full h-48 object-cover"
                />
              )}
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-900">{item.name}</h3>
                <p className="text-gray-600 text-sm mt-1 line-clamp-2">{item.description}</p>
                <p className="text-primary-600 font-bold mt-2">${item.price.toFixed(2)}</p>
              </div>
            </div>
          ))}
      </div>

      {/* Botón flotante del carrito */}
      {getCartItemsCount() > 0 && (
        <div className="fixed bottom-4 right-4 z-10">
          <button
            onClick={handleViewCart}
            className="bg-primary-600 text-white rounded-full p-4 shadow-lg flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            <span className="font-bold">{getCartItemsCount()}</span>
          </button>
        </div>
      )}

      {/* Modal de detalle de item */}
      {itemModalOpen && selectedItem && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg max-w-lg w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              {selectedItem.image && (
                <img
                  src={selectedItem.image}
                  alt={selectedItem.name}
                  className="w-full h-48 object-cover rounded-lg mb-4"
                />
              )}
              <h2 className="text-2xl font-bold text-gray-900 mb-2">{selectedItem.name}</h2>
              <p className="text-gray-700 mb-4">{selectedItem.description}</p>
              <p className="text-primary-600 font-bold text-xl mb-6">${selectedItem.price.toFixed(2)}</p>
              
              <div className="mb-4">
                <label htmlFor="quantity" className="block text-sm font-medium text-gray-700 mb-1">
                  Cantidad
                </label>
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() => setQuantity(Math.max(1, quantity - 1))}
                    className="bg-gray-200 text-gray-700 rounded-l-md px-3 py-2"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    id="quantity"
                    min="1"
                    value={quantity}
                    onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                    className="border-t border-b border-gray-300 text-center w-16 py-2"
                  />
                  <button
                    type="button"
                    onClick={() => setQuantity(quantity + 1)}
                    className="bg-gray-200 text-gray-700 rounded-r-md px-3 py-2"
                  >
                    +
                  </button>
                </div>
              </div>
              
              <div className="mb-6">
                <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">
                  Notas especiales
                </label>
                <textarea
                  id="notes"
                  rows="3"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Ej: Sin cebolla, término medio, etc."
                  className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                ></textarea>
              </div>
              
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setItemModalOpen(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  onClick={handleAddToCart}
                  className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700"
                >
                  Agregar al carrito - ${(selectedItem.price * quantity).toFixed(2)}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal del carrito */}
      {cartModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg max-w-lg w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Tu pedido</h2>
              
              {cartItems.length === 0 ? (
                <p className="text-gray-600 py-4">Tu carrito está vacío</p>
              ) : (
                <>
                  <div className="divide-y divide-gray-200">
                    {cartItems.map((item) => (
                      <div key={item.id} className="py-4 flex justify-between">
                        <div className="flex-1">
                          <h3 className="text-lg font-medium text-gray-900">{item.name}</h3>
                          <p className="text-gray-600 text-sm">${item.price.toFixed(2)} x {item.quantity}</p>
                          {item.notes && (
                            <p className="text-gray-500 text-sm mt-1 italic">"{item.notes}"</p>
                          )}
                        </div>
                        <div className="flex items-center">
                          <span className="font-medium text-gray-900 mr-4">
                            ${(item.price * item.quantity).toFixed(2)}
                          </span>
                          <button
                            type="button"
                            onClick={() => removeFromCart(item.id)}
                            className="text-red-600 hover:text-red-800"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                  
                  <div className="mt-6 border-t border-gray-200 pt-4">
                    <div className="flex justify-between text-lg font-bold">
                      <span>Total:</span>
                      <span>${getCartTotal().toFixed(2)}</span>
                    </div>
                  </div>
                </>
              )}
              
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setCartModalOpen(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  Seguir pidiendo
                </button>
                {cartItems.length > 0 && (
                  <button
                    type="button"
                    onClick={handlePlaceOrder}
                    className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700"
                  >
                    Confirmar pedido
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuPage;

import { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';

// Crear el contexto de autenticación
const AuthContext = createContext();

// URL base para las peticiones a la API - forzada a producción
const API_URL = 'https://api.autopedidos.com';

// Log para depuración
console.log('Auth Context API URL:', API_URL);

// Configuración global de Axios
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Content-Type'] = 'application/json';

// Configurar interceptores para manejar errores
axios.interceptors.response.use(
  response => response,
  error => {
    console.error('Error en la petición:', error);
    return Promise.reject(error);
  }
);

// Proveedor del contexto de autenticación
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [loading, setLoading] = useState(true);

  // Configurar el token en las cabeceras de Axios
  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      localStorage.setItem('token', token);
      // Evitar múltiples llamadas a fetchCurrentUser
      if (!currentUser) {
        fetchCurrentUser();
      }
    } else {
      delete axios.defaults.headers.common['Authorization'];
      localStorage.removeItem('token');
      setCurrentUser(null);
      setLoading(false);
    }
  }, [token, currentUser]);

  // Obtener el usuario actual
  const fetchCurrentUser = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/auth/me`);
      setCurrentUser(response.data.user);
    } catch (error) {
      console.error('Error al obtener el usuario actual:', error);
      // Si hay un error de autenticación, limpiar el token
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        setToken(null);
      }
    } finally {
      setLoading(false);
    }
  };

  // Registrar un nuevo usuario
  const register = async (userData) => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/api/auth/register`, userData);
      setToken(response.data.token);
      toast.success('Registro exitoso');
      return response.data;
    } catch (error) {
      console.error('Error al registrar usuario:', error);
      const errorMessage = error.response?.data?.message || 'Error al registrar usuario';
      toast.error(errorMessage);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Iniciar sesión
  const login = async (credentials) => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/api/auth/login`, credentials);
      
      // Guardar el token primero
      const token = response.data.token;
      setToken(token);
      
      // Actualizar headers para la siguiente petición
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      // Obtener datos del usuario con el nuevo token
      try {
        const userData = await axios.get(`${API_URL}/api/auth/me`);
        setCurrentUser(userData.data.user);
      } catch (userError) {
        console.warn('Error al obtener datos del usuario:', userError);
        // Continuar aunque falle obtener el usuario, ya que el login fue exitoso
      }
      
      toast.success('Inicio de sesión exitoso', { id: 'login-success' });
      return response.data;
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      const errorMessage = error.response?.data?.message || 'Credenciales inválidas';
      toast.error(errorMessage, { id: 'login-error' });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Cerrar sesión
  const logout = () => {
    setToken(null);
    toast.success('Sesión cerrada');
  };

  // Verificar si el usuario está autenticado
  const isAuthenticated = () => {
    return !!token && !!currentUser;
  };

  const value = {
    currentUser,
    loading,
    register,
    login,
    logout,
    isAuthenticated,
    token
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Hook personalizado para usar el contexto de autenticación
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth debe ser usado dentro de un AuthProvider');
  }
  return context;
};

export default AuthContext;

// api-fix.js - Solución para el problema de rutas API
// Este archivo debe ser importado al inicio de main.jsx

import axios from 'axios';

// Configuración global de Axios
const API_URL = 'https://api.autopedidos.com';
axios.defaults.baseURL = API_URL;

// Función para corregir las rutas API
function fixApiRoutes() {
  console.log('🔧 Aplicando correcciones a rutas API...');
  
  // Sobrescribir los métodos HTTP de Axios
  const originalPost = axios.post;
  axios.post = function(url, data, config) {
    url = forceApiPrefix(url);
    console.log('📤 POST:', url, data);
    return originalPost(url, data, config);
  };
  
  const originalGet = axios.get;
  axios.get = function(url, config) {
    url = forceApiPrefix(url);
    console.log('📥 GET:', url);
    return originalGet(url, config);
  };
  
  const originalPut = axios.put;
  axios.put = function(url, data, config) {
    url = forceApiPrefix(url);
    console.log('📝 PUT:', url);
    return originalPut(url, data, config);
  };
  
  const originalDelete = axios.delete;
  axios.delete = function(url, config) {
    url = forceApiPrefix(url);
    console.log('🗑️ DELETE:', url);
    return originalDelete(url, config);
  };
  
  console.log('✅ Correcciones aplicadas a rutas API');
}

// Función para forzar el prefijo /api en todas las rutas
function forceApiPrefix(url) {
  // Si la URL es una URL completa (comienza con http)
  if (url.startsWith('http')) {
    // Si es la URL de la API pero sin el prefijo /api
    if (url.startsWith(API_URL) && !url.includes('/api/')) {
      // Buscar patrones como /auth/, /restaurants/, etc.
      const apiRoutePattern = /\/(auth|restaurants|menu|tables|orders|users)\//;
      if (apiRoutePattern.test(url)) {
        const newUrl = url.replace(API_URL, `${API_URL}/api`);
        console.log(`🔄 Corrigiendo URL absoluta: ${url} → ${newUrl}`);
        return newUrl;
      }
    }
    return url;
  }
  
  // Si la URL es relativa
  if (!url.startsWith('/api/')) {
    // Buscar patrones como /auth/, /restaurants/, etc.
    const apiRoutePattern = /^\/(auth|restaurants|menu|tables|orders|users)(\/|$)/;
    if (apiRoutePattern.test(url)) {
      const newUrl = `/api${url}`;
      console.log(`🔄 Corrigiendo URL relativa: ${url} → ${newUrl}`);
      return newUrl;
    }
  }
  
  return url;
}

// Exportar la función para ser usada en main.jsx
export default fixApiRoutes;

import { createContext, useState, useContext, useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';

// Desactivar todas las notificaciones duplicadas
toast.remove();

// Crear el contexto del carrito
const CartContext = createContext();

// Proveedor del contexto del carrito
export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [restaurantId, setRestaurantId] = useState(null);
  const [tableId, setTableId] = useState(null);
  const [tableNumber, setTableNumber] = useState(null);
  
  // Usar refs para evitar problemas de estado
  const cartItemsRef = useRef([]);
  const isInitialLoad = useRef(true);
  
  // Mantener la referencia actualizada
  useEffect(() => {
    cartItemsRef.current = cartItems;
  }, [cartItems]);

  // Cargar el carrito del localStorage al iniciar
  useEffect(() => {
    const storedCart = localStorage.getItem('cart');
    const storedRestaurantId = localStorage.getItem('restaurantId');
    const storedTableId = localStorage.getItem('tableId');
    const storedTableNumber = localStorage.getItem('tableNumber');

    if (storedCart) {
      try {
        const parsedCart = JSON.parse(storedCart);
        setCartItems(parsedCart);
        cartItemsRef.current = parsedCart;
      } catch (error) {
        console.error('Error al parsear el carrito:', error);
        setCartItems([]);
        cartItemsRef.current = [];
      }
    }

    if (storedRestaurantId) setRestaurantId(storedRestaurantId);
    if (storedTableId) setTableId(storedTableId);
    if (storedTableNumber) setTableNumber(storedTableNumber);
    
    isInitialLoad.current = false;
  }, []);

  // Guardar el carrito en localStorage cuando cambie
  useEffect(() => {
    if (!isInitialLoad.current) {
      localStorage.setItem('cart', JSON.stringify(cartItems));
    }
  }, [cartItems]);

  // Guardar el restaurante y mesa en localStorage cuando cambien
  useEffect(() => {
    if (!isInitialLoad.current) {
      if (restaurantId) localStorage.setItem('restaurantId', restaurantId);
      if (tableId) localStorage.setItem('tableId', tableId);
      if (tableNumber) localStorage.setItem('tableNumber', tableNumber);
    }
  }, [restaurantId, tableId, tableNumber]);

  // Establecer información de la mesa
  const setTableInfo = (restaurant, table) => {
    // Si cambia el restaurante, limpiar el carrito
    if (restaurantId && restaurantId !== restaurant.id) {
      clearCart();
    }
    
    setRestaurantId(restaurant.id);
    setTableId(table.id);
    setTableNumber(table.table_number);
    
    // Guardar inmediatamente en localStorage
    localStorage.setItem('restaurantId', restaurant.id);
    localStorage.setItem('tableId', table.id);
    localStorage.setItem('tableNumber', table.table_number);
  };

  // Agregar un item al carrito - Solución definitiva
  const addToCart = (item, quantity = 1, notes = '') => {
    console.log('Agregando al carrito:', item.name, 'cantidad:', quantity);
    
    // Usar un enfoque directo sin depender del estado React
    // Leer directamente del localStorage
    let currentCart = [];
    try {
      const savedCart = localStorage.getItem('cart');
      currentCart = savedCart ? JSON.parse(savedCart) : [];
    } catch (error) {
      console.error('Error al leer el carrito del localStorage:', error);
      // Si hay error, reiniciar el carrito
      localStorage.setItem('cart', JSON.stringify([]));
    }
    
    // Buscar si el item ya existe
    const existingIndex = currentCart.findIndex(cartItem => cartItem.id === item.id);
    
    // Crear una copia del carrito para modificar
    let newCart = [...currentCart];
    
    if (existingIndex >= 0) {
      // Actualizar item existente
      newCart[existingIndex] = {
        ...newCart[existingIndex],
        quantity: newCart[existingIndex].quantity + quantity,
        notes: notes || newCart[existingIndex].notes
      };
      console.log('Item actualizado en el carrito');
    } else {
      // Agregar nuevo item
      newCart.push({
        ...item,
        quantity,
        notes
      });
      console.log('Nuevo item agregado al carrito');
    }
    
    // Guardar en localStorage
    try {
      localStorage.setItem('cart', JSON.stringify(newCart));
      console.log('Carrito guardado en localStorage:', newCart);
    } catch (error) {
      console.error('Error al guardar el carrito en localStorage:', error);
    }
    
    // Actualizar el estado React y la referencia
    cartItemsRef.current = newCart;
    setCartItems(newCart);
    
    // Mostrar notificación (solo una)
    toast.remove(); // Eliminar todas las notificaciones existentes
    if (existingIndex >= 0) {
      toast.success(`${item.name} actualizado en el carrito`);
    } else {
      toast.success(`${item.name} agregado al carrito`);
    }
    
    // Verificar que el item se haya agregado correctamente
    setTimeout(() => {
      try {
        const verificationCart = localStorage.getItem('cart');
        const parsedCart = JSON.parse(verificationCart);
        const itemInCart = parsedCart.some(cartItem => cartItem.id === item.id);
        
        if (!itemInCart) {
          console.warn('Verificación falló: el item no está en el carrito, intentando nuevamente');
          // Forzar la actualización del carrito nuevamente
          localStorage.setItem('cart', JSON.stringify(newCart));
          setCartItems(newCart);
        } else {
          console.log('Verificación exitosa: el item está en el carrito');
        }
      } catch (error) {
        console.error('Error en la verificación del carrito:', error);
      }
    }, 100);
  };

  // Actualizar la cantidad de un item en el carrito
  const updateCartItemQuantity = (itemId, quantity) => {
    if (quantity <= 0) {
      removeFromCart(itemId);
      return;
    }

    const currentCartItems = cartItemsRef.current;
    const newCartItems = currentCartItems.map(item =>
      item.id === itemId ? { ...item, quantity } : item
    );
    
    // Actualizar la referencia inmediatamente
    cartItemsRef.current = newCartItems;
    
    // Actualizar el estado y localStorage
    setCartItems(newCartItems);
    localStorage.setItem('cart', JSON.stringify(newCartItems));
  };

  // Actualizar las notas de un item en el carrito
  const updateCartItemNotes = (itemId, notes) => {
    const currentCartItems = cartItemsRef.current;
    const newCartItems = currentCartItems.map(item =>
      item.id === itemId ? { ...item, notes } : item
    );
    
    // Actualizar la referencia inmediatamente
    cartItemsRef.current = newCartItems;
    
    // Actualizar el estado y localStorage
    setCartItems(newCartItems);
    localStorage.setItem('cart', JSON.stringify(newCartItems));
  };

  // Eliminar un item del carrito
  const removeFromCart = (itemId) => {
    const currentCartItems = cartItemsRef.current;
    const itemToRemove = currentCartItems.find(item => item.id === itemId);
    
    if (itemToRemove) {
      // Cancelar cualquier notificación anterior y mostrar una nueva
      toast.dismiss();
      toast.success(`${itemToRemove.name} eliminado del carrito`);
    }
    
    const newCartItems = currentCartItems.filter(item => item.id !== itemId);
    
    // Actualizar la referencia inmediatamente
    cartItemsRef.current = newCartItems;
    
    // Actualizar el estado y localStorage
    setCartItems(newCartItems);
    localStorage.setItem('cart', JSON.stringify(newCartItems));
  };

  // Limpiar el carrito
  const clearCart = () => {
    // Actualizar la referencia inmediatamente
    cartItemsRef.current = [];
    
    // Actualizar el estado y localStorage
    setCartItems([]);
    localStorage.setItem('cart', JSON.stringify([]));
    
    // Cancelar cualquier notificación anterior y mostrar una nueva
    toast.dismiss();
    toast.success('Carrito limpiado');
  };

  // Calcular el total del carrito
  const getCartTotal = () => {
    return cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };

  // Obtener la cantidad total de items en el carrito
  const getCartItemsCount = () => {
    return cartItems.reduce((count, item) => count + item.quantity, 0);
  };

  // Formatear el carrito para enviar al servidor
  const formatCartForOrder = () => {
    return cartItems.map(item => ({
      menu_item_id: item.id,
      quantity: item.quantity,
      notes: item.notes
    }));
  };

  const value = {
    cartItems,
    restaurantId,
    tableId,
    tableNumber,
    setTableInfo,
    addToCart,
    updateCartItemQuantity,
    updateCartItemNotes,
    removeFromCart,
    clearCart,
    getCartTotal,
    getCartItemsCount,
    formatCartForOrder
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

// Hook personalizado para usar el contexto del carrito
export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart debe ser usado dentro de un CartProvider');
  }
  return context;
};

export default CartContext;
